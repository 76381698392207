<template>
  <div class="bar-fixed-layout">
    <div class="header-bar-fixed">
      <slot name="hader"></slot>
    </div>
    <div class="content-scroll">
      <slot></slot>
    </div>
    <div class="footer-bar-fixed">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'bar-fixed-layout',
  components: {},
  setup() {
    return {}
  }
}
</script>
<style lang="less" scoped>
.bar-fixed-layout {
  height: 100%;
  display: flex;
  flex-direction: column;
  .content-scroll {
    position: relative;
    flex: 1;
    overflow: auto;
  }
}
</style>
