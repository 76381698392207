<template>
  <bar-fixed-layout class="tool-welcome">
    <div v-if="!editable" class="welcome_mask"></div>
    <div class="welcome_tltle">添加客户为好友后，客户将收到此欢迎语</div>
    <van-field
      class="welcome_textarea"
      v-model="form.qw_content"
      autosize
      type="textarea"
      maxlength="200"
      placeholder="在此输入内容，最多不超过200字"
      show-word-limit
    />
    <van-checkbox-group class="checkbox_group" direction="horizontal">
      <van-checkbox
        name="image"
        :bind-group="false"
        v-model="bindImage"
        shape="square"
        class="checkbox_image"
        @change="onCheckChange($event, 'bindLink')"
      >
        图片
      </van-checkbox>
      <!-- <van-checkbox
        name="link"
        :bind-group="false"
        v-model="bindLink"
        shape="square"
        @change="onCheckChange($event, 'bindImage')"
      >
        网址
      </van-checkbox> -->
    </van-checkbox-group>
    <div class="image_box" v-show="bindImage">
      <van-uploader
        v-model="imageList"
        name="imageList"
        accept="image/png, image/jpg"
        :max-size="2 * 1024 * 1023"
        :max-count="1"
        :after-read="afterReadImage"
        @delete="onPicDeleteImage"
        @oversize="onOversize"
      >
        <div class="custom_upload">
          <van-icon name="plus" class="upload_icon" />
          <div>添加照片</div>
        </div>
      </van-uploader>
    </div>
    <div class="link_box" v-show="bindLink">
      <van-form ref="urlFormRef">
        <van-field
          v-model="form.qw_link_title"
          name="qw_link_title"
          required
          label="标题"
          maxlength="10"
          placeholder="不得超过10字"
          :rules="[{ required: true, message: '请填写标题' }]"
        />
        <van-field
          v-model="form.qw_link_desc"
          type="textarea"
          name="qw_link_desc"
          label="描述"
          maxlength="100"
          rows="1"
          autosize
          placeholder="在此输入内容，最多不超过100字"
        />
        <van-field
          v-model="form.qw_link_url"
          name="qw_link_url"
          label="链接"
          maxlength="300"
          required
          placeholder="输入http或者https开头的合法的链接"
          :rules="[
            { required: true, message: '请填写链接' },
            {
              pattern: /(http|https):\/\/([\w.]+\/?)\S*/,
              message: '输入http或者https开头的合法的链接'
            }
          ]"
        />
        <van-field name="uploader" label="文件上传">
          <template #input>
            <van-uploader
              v-model="linkImageList"
              accept="image/png, image/jpg"
              name="linkImageList"
              label="链接封面"
              :max-size="2 * 1024 * 1024"
              :max-count="1"
              :after-read="afterReadLink"
              @oversize="onOversize"
              @delete="onPicDeleteLink"
            >
              <div class="custom_upload">
                <van-icon name="plus" class="upload_icon" />
                <div>添加封面</div>
              </div>
            </van-uploader>
          </template>
        </van-field>
      </van-form>
    </div>
    <template v-slot:footer>
      <div class="row_buttons">
        <van-button
          v-if="editable"
          class="rol_button"
          type="primary"
          block
          @click="onSave"
        >
          保存
        </van-button>
        <van-button
          v-if="canDelete && editable"
          class="rol_button"
          block
          @click="onDelete"
        >
          删除
        </van-button>
        <van-button
          v-if="!editable"
          class="rol_button"
          type="primary"
          block
          @click="editable = true"
        >
          编辑
        </van-button>
      </div>
    </template>
  </bar-fixed-layout>
</template>

<script>
import barFixedLayout from '../../../components/layout/bar-fixed-layout/index.vue'
import { deleteGuideWelcomeMsgs } from '@/service/tool.service.js'
import {
  getGuideWelcomeMsgsPre,
  setGuideWelcomeMsgsPres,
  addQWImageSourcesPre
} from './welcome.service'
import {
  Button,
  Field,
  Checkbox,
  CheckboxGroup,
  Uploader,
  Toast,
  Icon,
  Form,
  Notify,
  Dialog
} from 'vant'
import { ref, reactive, toRefs, getCurrentInstance } from 'vue'
import { useRouter } from 'vue-router'
export default {
  name: 'welcome',
  components: {
    [barFixedLayout.name]: barFixedLayout,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    [Form.name]: Form,
    [Field.name]: Field,
    [Uploader.name]: Uploader,
    [Icon.name]: Icon
  },
  inject: ['$refresh'],
  setup(props) {
    const { ctx } = getCurrentInstance()
    const router = useRouter()
    const urlFormRef = ref(null)
    const state = reactive({
      canDelete: false,
      editable: false, // 是否可编辑
      bindImage: false, // 绑定图片
      bindLink: false, // 绑定图文链接
      imageList: [], // 图片list
      linkImageList: [] // 封面list
    })
    const form = reactive({
      qw_content: '', // 欢迎语内容
      qw_media_id: '', // 素材媒体id
      qw_pic_url: '', // 素材图片url
      qw_link_title: '', // 图文链接标题
      qw_link_desc: '', // 图文链接描述
      qw_link_picurl: '', // 图文链接图片地址
      qw_link_url: '' // 图文链接地址
    })
    const onCheckChange = (val, checkedField) => {
      if (val) state[checkedField] = false
    }
    // 触发图片上传逻辑
    const afterRead = (file, sendType) => {
      addQWImageSourcesPre(file).then(({ url, media_id }) => {
        file.status = 'success'
        if (sendType === 'IMAGE') {
          form.qw_pic_url = url
          form.qw_media_id = media_id
        } else {
          form.qw_link_picurl = url
        }
      })
    }
    const afterReadImage = (file) => afterRead(file, 'IMAGE')
    const afterReadLink = (file) => afterRead(file, 'LINK')
    // 删除图片后清空表单url的值
    const onPicDeleteImage = () => {
      form.qw_pic_url = ''
    }
    const onPicDeleteLink = () => {
      form.qw_link_picurl = ''
    }
    const onOversize = (file) => {
      Toast('图片大小已超过 2M，请重新选择图片')
    }
    // 发请求提交表单
    const saveWelcomMsgs = () => {
      setGuideWelcomeMsgsPres(state, form).then(() => {
        state.editable = false
        router.go(-1)
        Toast('设置成功')
      })
    }
    // 表单校验
    const onSave = () => {
      if (state.bindLink) {
        urlFormRef.value.resetValidation()
        urlFormRef.value
          .validate()
          .then(() => {
            saveWelcomMsgs()
          })
          .catch((data) => {
            Notify({ type: 'danger', message: data[0].message })
          })
      } else if (!form.qw_content && !form.qw_pic_url) {
        Notify({ type: 'danger', message: '欢迎语、图片、网址请至少填写一项' })
      } else {
        saveWelcomMsgs()
      }
    }
    // 删除
    const onDelete = () => {
      Dialog.confirm({
        title: '温馨提示',
        message: '删除欢迎语后客户可能无法收到欢迎语'
      }).then(() => {
        deleteGuideWelcomeMsgs().then(() => {
          Toast('删除成功')
          ctx.$refresh()
        })
      })
    }
    const init = () => {
      // 获取欢迎语设置并设置默认值
      getGuideWelcomeMsgsPre()
        .then(({ newState, newForm }) => {
          Object.assign(state, newState)
          Object.assign(form, newForm)
        })
        .catch((error) => {
          console.log('请求失败', error)
          Dialog.alert({
            message: error.msg
          }).then(() => {
            router.go(-1)
          })
        })
    }
    init()
    return {
      ...toRefs(state),
      form,
      urlFormRef,
      onCheckChange,
      afterReadImage,
      afterReadLink,
      onPicDeleteImage,
      onPicDeleteLink,
      onOversize,
      onSave,
      onDelete
    }
  }
}
</script>

<style lang="less" scoped>
@import url('./index');
</style>
